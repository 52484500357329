import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private readonly config: MatSnackBarConfig;

  constructor(
    private readonly snackbar: MatSnackBar,
    private readonly zone: NgZone,
    private readonly languageService: LanguageService,
  ) {
    this.config = new MatSnackBarConfig();
    this.config.panelClass = ['custom-snack-bar'];
    this.config.verticalPosition = 'top';
    this.config.horizontalPosition = 'right';
    this.config.duration = 7000;
  }

  error(translationKey: string, addLeadingErrorPrefix = true) {
    this.config.panelClass = ['custom-snack-bar', 'error'];
    const translationErrorPrefix = addLeadingErrorPrefix ? 'errors.' : '';

    this.show(translationErrorPrefix + translationKey);
  }

  success(translationKey: string) {
    this.config.panelClass = ['custom-snack-bar', 'success'];
    this.show(translationKey);
  }

  warning(translationKey: string) {
    this.config.panelClass = ['custom-snack-bar', 'warning'];
    this.show(translationKey);
  }

  info(translationKey: string) {
    this.config.panelClass = ['custom-snack-bar', 'info'];
    this.show(translationKey);
  }

  private show(translationKey: string, config?: MatSnackBarConfig) {
    const message = this.languageService.translateOnRuntime(translationKey);
    config = config ?? this.config;
    this.zone.run(() => {
      this.snackbar.open(message, 'x', config);
    });
  }
}
